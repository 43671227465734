// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { defineAsyncComponent, createApp } from 'vue';
import { LaravelRoutes, setLaravelRoutes } from './shared/helpers/routes';
import translate from './shared/helpers/translate';

// Directives
import { lazyLoad } from './shared/directives/lazy-load.directive';
import { scrollEnd } from './shared/directives/scroll-end.directive';

import.meta.glob(['../images/**', '../fonts/**']);

// Icones
const VIconX = defineAsyncComponent(() => import('./icons/VIconX.vue'));
const VIconFaq = defineAsyncComponent(() => import('./icons/VIconFaq.vue'));
const VIconHelp = defineAsyncComponent(() => import('./icons/VIconHelp.vue'));
const VIconUser = defineAsyncComponent(() => import('./icons/VIconUser.vue'));
const VIconMail = defineAsyncComponent(() => import('./icons/VIconMail.vue'));
const VIconCart = defineAsyncComponent(() => import('./icons/VIconCart.vue'));
const VIconStar = defineAsyncComponent(() => import('./icons/VIconStar.vue'));
const VIconPhone = defineAsyncComponent(() => import('./icons/VIconPhone.vue'));
const VIconTrash = defineAsyncComponent(() => import('./icons/VIconTrash.vue'));
const VIconHeart = defineAsyncComponent(() => import('./icons/VIconHeart.vue'));
const VIconCheck = defineAsyncComponent(() => import('./icons/VIconCheck.vue'));
const VIconPencil = defineAsyncComponent(() => import('./icons/VIconPencil.vue'));
const VLogoGoogle = defineAsyncComponent(() => import('./icons/VLogoGoogle.vue'));
const VIconTriman = defineAsyncComponent(() => import('./icons/VIconTriman.vue'));
const VIconLocked = defineAsyncComponent(() => import('./icons/VIconLocked.vue'));
const VIconSearch = defineAsyncComponent(() => import('./icons/VIconSearch.vue'));
const VIconPhotos = defineAsyncComponent(() => import('./icons/VIconPhotos.vue'));
const VIconCB = defineAsyncComponent(() => import('./icons/payment/VIconCB.vue'));
const VIconTwitter = defineAsyncComponent(() => import('./icons/VIconTwitter.vue'));
const VIconFilters = defineAsyncComponent(() => import('./icons/VIconFilters.vue'));
const VIconYoutube = defineAsyncComponent(() => import('./icons/VIconYoutube.vue'));
const VIconChevronR = defineAsyncComponent(() => import('./icons/VIconChevronR.vue'));
const VLogoFacebook = defineAsyncComponent(() => import('./icons/VLogoFacebook.vue'));
const VIconChevronL = defineAsyncComponent(() => import('./icons/VIconChevronL.vue'));
const VIconChevronD = defineAsyncComponent(() => import('./icons/VIconChevronD.vue'));
const VIconDownload = defineAsyncComponent(() => import('./icons/VIconDownload.vue'));
const VIconChevronU = defineAsyncComponent(() => import('./icons/VIconChevronU.vue'));
const VIconFacebook = defineAsyncComponent(() => import('./icons/VIconFacebook.vue'));
const VIconOney = defineAsyncComponent(() => import('./icons/payment/VIconOney.vue'));
const VIconVisa = defineAsyncComponent(() => import('./icons/payment/VIconVisa.vue'));
const VIconPinterest = defineAsyncComponent(() => import('./icons/VIconPinterest.vue'));
const VIconInstagram = defineAsyncComponent(() => import('./icons/VIconInstagram.vue'));
const VIconArrowLeft = defineAsyncComponent(() => import('./icons/VIconArrowLeft.vue'));
const VIconPaypal = defineAsyncComponent(() => import('./icons/payment/VIconPaypal.vue'));
const VIconArrowRight = defineAsyncComponent(() => import('./icons/VIconArrowRight.vue'));
const VIconOney3x4x = defineAsyncComponent(() => import('./icons/payment/VIconOney3x4x.vue'));
const VIconScalapay = defineAsyncComponent(() => import('./icons/payment/VIconScalapay.vue'));
const paymentMethod = defineAsyncComponent(() => import('./components/footer/paymentMethod.vue'));
const VIconBankwireIt = defineAsyncComponent(() => import('./icons/payment/VIconBankwireIt.vue'));
const VIconMastercard = defineAsyncComponent(() => import('./icons/payment/VIconMastercard.vue'));
const VIconBankwireEs = defineAsyncComponent(() => import('./icons/payment/VIconBankwireEs.vue'));
const VIconBankwireFr = defineAsyncComponent(() => import('./icons/payment/VIconBankwireFr.vue'));
const VIconBankwireDe = defineAsyncComponent(() => import('./icons/payment/VIconBankwireDe.vue'));
const VIconExclamationTriangle = defineAsyncComponent(() => import('./icons/VIconExclamationTriangle.vue'));
const VIconAmericanExpress = defineAsyncComponent(() => import('./icons/payment/VIconAmericanExpress.vue'));

const TheSearch = defineAsyncComponent(() => import('./components/search/TheSearch.vue'));

// Checkout
const TheCheckoutContainer = defineAsyncComponent(() => import('./components/checkout/TheCheckoutContainer.vue'));
const ThePaymentReturn = defineAsyncComponent(() => import('./components/checkout/return/ThePaymentReturn.vue'));

const MilibooLogo = defineAsyncComponent(() => import('./icons/milibooLogo.vue'));
const ProForm = defineAsyncComponent(() => import('./components/pro-form.vue'));

// Common
const VImg = defineAsyncComponent(() => import('./components/common/VImg.vue'));
const Alert = defineAsyncComponent(() => import('./components/common/alert.vue'));
const Slider = defineAsyncComponent(() => import('./components/common/slider.vue'));
const HelpIcon = defineAsyncComponent(() => import('./components/common/helpIcon.vue'));
const BtnSubmit = defineAsyncComponent(() => import('./components/common/btnSubmit.vue'));
const InputError = defineAsyncComponent(() => import('./components/common/inputError.vue'));
const Breadcrumb = defineAsyncComponent(() => import('./components/common/breadcrumb.vue'));
const UserIconLogin = defineAsyncComponent(() => import('./components/common/userIconLogin.vue'));
const MarketingInfos = defineAsyncComponent(() => import('./components/common/marketingInfos.vue'));
const RecentlyConsultedContainer = defineAsyncComponent(() => import('./components/common/recentlyConsultedContainer.vue'));

// Footer
const SimpleList = defineAsyncComponent(() => import('./components/footer/simpleList.vue'));
const Disclosure = defineAsyncComponent(() => import('./components/footer/disclosure.vue'));
const ShopsDropdown = defineAsyncComponent(() => import('./components/footer/shopsDropdown.vue'));
const NewsletterEmail = defineAsyncComponent(() => import('./components/footer/newsletterEmail.vue'));

// Menu
const TheMainMenu = defineAsyncComponent(() => import('./components/menu/TheMainMenu.vue'));
const TheSecondaryMenu = defineAsyncComponent(() => import('./components/menu/TheSecondaryMenu.vue'));
const TheMainMenuMobile = defineAsyncComponent(() => import('./components/menu/TheMainMenuMobile.vue'));

// List
const TheListContainer = defineAsyncComponent(() => import('./components/list/TheListContainer.vue'));
const TheChildrenSlider = defineAsyncComponent(() => import('./components/list/TheChildrenSlider.vue'));

// Product
const TheProductPrice = defineAsyncComponent(() => import('./components/product/TheProductPrice.vue'));
const TheProductAction = defineAsyncComponent(() => import('./components/product/TheProductAction.vue'));
const TheProductOptions = defineAsyncComponent(() => import('./components/product/TheProductOptions.vue'));
const TheProductQuantity = defineAsyncComponent(() => import('./components/product/TheProductQuantity.vue'));
const TheOneySimulations = defineAsyncComponent(() => import('./components/product/TheOneySimulations.vue'));
const TheProductVariation = defineAsyncComponent(() => import('./components/product/TheProductVariation.vue'));
const TheProductDescription = defineAsyncComponent(() => import('./components/product/TheProductDescription.vue'));
const TheProductReviewList = defineAsyncComponent(() => import('./components/product/review/TheProductReviewList.vue'));
const TheProductImagesCarousel = defineAsyncComponent(() => import('./components/product/TheProductImagesCarousel.vue'));
const TheInformationsDisclosure = defineAsyncComponent(() => import('./components/product/TheInformationsDisclosure.vue'));
const TheReconditionnedProducts = defineAsyncComponent(() => import('./components/product/TheReconditionnedProducts.vue'));
const TheProductReviewCreate = defineAsyncComponent(() => import('./components/product/review/TheProductReviewCreate.vue'));

// Auth
const TheAuth = defineAsyncComponent(() => import('./features/auth/components/TheAuth.vue'));

// Forgotten password
const TheForgottenPassword = defineAsyncComponent(() => import('./features/forgottenPassword/components/TheForgottenPassword.vue'));
const TheResetPassword = defineAsyncComponent(() => import('./features/resetPassword/components/TheResetPassword.vue'));

// Cart
const CartWrapper = defineAsyncComponent(() => import('./components/cart/cartWrapper.vue'));
const CartMenuWrapper = defineAsyncComponent(() => import('./components/cart/cartMenuWrapper.vue'));

// Newsletter
const TheNewsletter = defineAsyncComponent(() => import('./features/myAccount/newsletter/components/TheNewsletter.vue'));
const LeftMenuMobile = defineAsyncComponent(() => import('./components/customer/leftMenuMobile.vue'));
const CustomerGiftCards = defineAsyncComponent(() => import('./features/myAccount/giftCard/components/TheGiftCards.vue'));
const FidelityPointsUsagePopover = defineAsyncComponent(() => import('./components/customer/fidelityPointsUsagePopover.vue'));

// Customer Addresses
const CustomerAddressList = defineAsyncComponent(() => import('./components/customer/address/addressList.vue'));
const CustomerAddressForm = defineAsyncComponent(() => import('./features/myAccount/address/components/TheAddressForm.vue'));

// Wishlist
const Wishlist = defineAsyncComponent(() => import('./components/wishlist/wishlist.vue'));
const WishlistProduct = defineAsyncComponent(() => import('./components/wishlist/wishlistProduct.vue'));
const TheWishlistContainer = defineAsyncComponent(() => import('./components/wishlist/TheWishlistContainer.vue'));
const TheEmptyWishlist = defineAsyncComponent(() => import('./components/wishlist/partials/TheEmptyWishlist.vue'));

// AccountInformation
const AccountInformation = defineAsyncComponent(() => import('./components/customer/accountInformation.vue'));

// Faq
const TheFaq = defineAsyncComponent(() => import('./components/faq/the-faq.vue'));

// Sponsorship
const Sponsorship = defineAsyncComponent(() => import('./features/sponsorship/components/TheSponsorship.vue'));

// Wishlist
const TheIconWishlist = defineAsyncComponent(() => import('./components/wishlist/TheIconWishlist.vue'));

// Notification
const TheNotification = defineAsyncComponent(() => import('./components/common/TheNotification.vue'));

// Aftersale
const TheAftersaleCreate = defineAsyncComponent(() => import('./components/customer/aftersale/TheAftersaleCreate.vue'));
const TheAftersaleFollowUp = defineAsyncComponent(() => import('./components/customer/aftersale/TheAftersaleFollowUp.vue'));
const AftersaleListItem = defineAsyncComponent(() => import('./components/customer/aftersale/AftersaleListItem.vue'));
const AftersaleListEligibleItem = defineAsyncComponent(() => import('./components/customer/aftersale/AftersaleListEligibleItem.vue'));

const dataApp = document.getElementById('app');

// Translations
const defaultLocale = dataApp.dataset.locale;
const { country } = dataApp.dataset;

translate.setTranslations(dataApp.dataset.translations);

// Routes
setLaravelRoutes(dataApp.dataset.jsRoutes);

// Create app
const app = createApp({});

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
  integrations: [],
  debug: false,
});

if (dataApp.dataset.customer !== '') {
  Sentry.setUser({ id: dataApp.dataset.customer });
}

if (import.meta.env.DEV === true && import.meta.env.MODE === 'development') {
  Sentry.setTag('developper', import.meta.env.VITE_SENTRY_DEVELOPPER_NAME);
}

// Search
app.component('the-search', TheSearch);

// Icones
app.component('v-icon-x', VIconX);
app.component('v-icon-cb', VIconCB);
app.component('v-icon-faq', VIconFaq);
app.component('v-icon-help', VIconHelp);
app.component('v-icon-cart', VIconCart);
app.component('v-icon-user', VIconUser);
app.component('v-icon-star', VIconStar);
app.component('v-icon-mail', VIconMail);
app.component('v-icon-visa', VIconVisa);
app.component('v-icon-oney', VIconOney);
app.component('v-icon-check', VIconCheck);
app.component('v-icon-phone', VIconPhone);
app.component('v-icon-trash', VIconTrash);
app.component('miliboo-logo', MilibooLogo);
app.component('v-icon-heart', VIconHeart);
app.component('v-icon-locked', VIconLocked);
app.component('v-logo-google', VLogoGoogle);
app.component('v-icon-photos', VIconPhotos);
app.component('v-icon-paypal', VIconPaypal);
app.component('v-icon-search', VIconSearch);
app.component('v-icon-triman', VIconTriman);
app.component('v-icon-pencil', VIconPencil);
app.component('v-icon-filters', VIconFilters);
app.component('v-icon-youtube', VIconYoutube);
app.component('v-icon-twitter', VIconTwitter);
app.component('v-icon-facebook', VIconFacebook);
app.component('v-logo-facebook', VLogoFacebook);
app.component('v-icon-download', VIconDownload);
app.component('v-icon-chevron-r', VIconChevronR);
app.component('v-icon-scalapay', VIconScalapay);
app.component('v-icon-chevron-l', VIconChevronL);
app.component('v-icon-chevron-d', VIconChevronD);
app.component('v-icon-oney_3x4x', VIconOney3x4x);
app.component('v-payment-method', paymentMethod);
app.component('v-icon-chevron-u', VIconChevronU);
app.component('v-icon-instagram', VIconInstagram);
app.component('v-icon-pinterest', VIconPinterest);
app.component('v-icon-arrow-left', VIconArrowLeft);
app.component('v-icon-arrow-right', VIconArrowRight);
app.component('v-icon-bankwire_de', VIconBankwireDe);
app.component('v-icon-bankwire_fr', VIconBankwireFr);
app.component('v-icon-bankwire_es', VIconBankwireEs);
app.component('v-icon-bankwire_it', VIconBankwireIt);
app.component('v-icon-mastercard', VIconMastercard);
app.component('v-icon-american_express', VIconAmericanExpress);
app.component('v-icon-exclamation-triangle', VIconExclamationTriangle);

// Checkout
app.component('the-payment-return', ThePaymentReturn);
app.component('the-checkout-container', TheCheckoutContainer);

// Common
app.component('v-img', VImg);
app.component('alert', Alert);
app.component('slider', Slider);
app.component('help-icon', HelpIcon);
app.component('btn-submit', BtnSubmit);
app.component('breadcrumb', Breadcrumb);
app.component('input-error', InputError);
app.component('marketing-infos', MarketingInfos);
app.component('user-icon-login', UserIconLogin);
app.component('recently-consulted-container', RecentlyConsultedContainer);

// Footer
app.component('disclosure', Disclosure);
app.component('simple-list', SimpleList);
app.component('shops-dropdown', ShopsDropdown);
app.component('newsletter-email', NewsletterEmail);

// Menu
app.component('the-main-menu', TheMainMenu);
app.component('the-secondary-menu', TheSecondaryMenu);
app.component('the-main-menu-mobile', TheMainMenuMobile);

// List
app.component('the-list-container', TheListContainer);
app.component('the-children-slider', TheChildrenSlider);

// Product
app.component('the-product-price', TheProductPrice);
app.component('the-product-action', TheProductAction);
app.component('the-product-options', TheProductOptions);
app.component('the-product-quantity', TheProductQuantity);
app.component('the-oney-simulations', TheOneySimulations);
app.component('the-product-variation', TheProductVariation);
app.component('the-product-review-list', TheProductReviewList);
app.component('the-product-description', TheProductDescription);
app.component('the-product-review-create', TheProductReviewCreate);
app.component('the-product-images-carousel', TheProductImagesCarousel);
app.component('the-reconditionned-products', TheReconditionnedProducts);
app.component('the-informations-disclosure', TheInformationsDisclosure);

// Auth
app.component('the-auth', TheAuth);

// Forgotten password
app.component('the-forgotten-password', TheForgottenPassword);
app.component('the-reset-password', TheResetPassword);

// Cart
app.component('cart-wrapper', CartWrapper);
app.component('cart-menu-wrapper', CartMenuWrapper);

// Newsletter
app.component('the-newsletter', TheNewsletter);
app.component('left-menu-mobile', LeftMenuMobile);
app.component('customer-gift-cards', CustomerGiftCards);
app.component('fidelity-points-usage-popover', FidelityPointsUsagePopover);

// Aftersale
app.component('the-aftersale-create', TheAftersaleCreate);
app.component('the-aftersale-follow-up', TheAftersaleFollowUp);
app.component('aftersale-list-item', AftersaleListItem);
app.component('aftersale-list-eligible-item', AftersaleListEligibleItem);

// Addresses
app.component('customer-address-list', CustomerAddressList);
app.component('customer-address-form', CustomerAddressForm);

// Wishlist
app.component('wishlist', Wishlist);
app.component('wishlist-product', WishlistProduct);
app.component('the-icon-wishlist', TheIconWishlist);
app.component('the-empty-wishlist', TheEmptyWishlist);
app.component('the-wishlist-container', TheWishlistContainer);

// Notification
app.component('the-notification', TheNotification);

// AccountInformation
app.component('account-information', AccountInformation);

// Faq
app.component('the-faq', TheFaq);

// ProForm
app.component('pro-form', ProForm);

// Sponsorship
app.component('the-sponsorship', Sponsorship);

// Provides
app.provide('country', country);
app.provide('translate', translate);
app.provide('locale', defaultLocale);
app.provide('auth', dataApp.dataset.auth);
app.provide('fallbackLocale', dataApp.dataset.fallback_locale);

app.use(LaravelRoutes);
app.use(VueQueryPlugin);

// Directives
app.directive('lazy-load', lazyLoad);
app.directive('scroll-end', scrollEnd);

app.mount('#app');
