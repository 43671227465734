const elementImage = new Map();

const load = (element, src) => new Promise((resolve, reject) => {
  const image = new Image();
  image.onload = () => resolve(image.src);
  image.onerror = () => reject(src);
  image.onabort = () => reject(src);
  image.onloadend = () => elementImage.delete(element);
  elementImage.set(element, image);
  image.src = src;
});

const setImages = async (element, bindings) => {
  if (bindings?.value) {
    try {
      const src = await load(element, bindings.value);
      if (element.src !== src) {
        // eslint-disable-next-line no-param-reassign
        element.src = src;
      }
    } catch (src) {
      // eslint-disable-next-line no-console
      console.info(`Image "${src}" not loaded`);
    } finally {
      element.classList.remove(
        ...['blur', 'blur-sm', 'blur-md', 'blur-lg', 'blur-xl'],
      );
    }
  }
};

const updated = (element, bindings) => {
  setImages(element, bindings);
};

const mounted = (element, bindings) => {
  setImages(element, bindings);
};

const unmounted = (element) => elementImage.get(element)?.onabort();

export const lazyLoad = { mounted, unmounted, updated };
