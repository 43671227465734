/* eslint-disable no-param-reassign */
import { reactive, toRefs } from 'vue';

const state = reactive({
  routes: null,
});

function useRoutes() {
  const setRoutes = (routes) => {
    state.routes = JSON.parse(routes);
  };

  const route = (name, parameters = {}, prefixLocale = null) => {
    if (prefixLocale) {
      name = `prefixLang.${name}`;
      parameters.prefixLang = prefixLocale;
    }

    if (Object.keys(parameters).length === 0) {
      parameters = [];
    }

    if (typeof state.routes !== 'object') {
      console.error('No routes found.');
      return '';
    }

    if (typeof state.routes[name] !== 'object') {
      console.error(`Route ${name} is undefined.`);
      return '';
    }

    const item = { ...state.routes[name] };
    let param = null;

    Object.keys(item.parameters).forEach((key) => {
      param = item.parameters[key];
      if (parameters[param]) {
        item.route = item.route.replace(
          `{${param}}`,
          parameters[param],
        );
      } else {
        item.route = item.route.replace(`/{${param}}`, '');
      }
    });
    return item.route;
  };

  return { ...toRefs(state), setRoutes, route };
}

export default useRoutes;

const LaravelRoutes = {
  install(app) {
    app.provide('jsRoutes', useRoutes());
  },
};

function setLaravelRoutes(routes) {
  useRoutes().setRoutes(routes);
}

export { LaravelRoutes, setLaravelRoutes };
