const scrollOption = { passive: true };

const getDistanceFromDocumentBottom = (element) => Math.max(
  document.body.scrollHeight,
  document.body.offsetHeight,
  document.documentElement.clientHeight,
  document.documentElement.scrollHeight,
  document.documentElement.offsetHeight,
)
  - (window.pageYOffset + element.getBoundingClientRect().bottom);

const listener = (element, callback, elementListener) => {
  window.removeEventListener('scroll', elementListener);
  setTimeout(() => {
    if (
      window.innerHeight + window.pageYOffset
      > window.document.body.offsetHeight - getDistanceFromDocumentBottom(element)
    ) {
      callback();
    } else {
      window.addEventListener('scroll', elementListener, scrollOption);
    }
  }, 100);
};

const mounted = (element, binding) => {
  if (binding.value instanceof Function) {
    const elementListener = () => listener(element, binding.value, elementListener);
    window.addEventListener('scroll', elementListener, scrollOption);
  } else {
    // eslint-disable-next-line no-console
    console.warn('Directive "scroll-end" expect function on binding');
  }
};

const updated = (element, binding) => mounted(element, binding);

export const scrollEnd = { mounted, updated };
